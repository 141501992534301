.work-table[data-v-c3351f73] {
  width: 100%;
  height: 100%;
}
.work-table[data-v-c3351f73] .rowSpanCell {
  display: flex;
  align-items: center;
  background: #fff;
  border-bottom: 1px solid #d9dddf;
}
.work-table[data-v-c3351f73] .ag-header-group-cell-label .ag-header-group-text {
  width: 100%;
  text-align: center;
}
